import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import JuniperImage from '../images/Juniper_Recipe_Card.png';
import CorianderImage from '../images/Coriander_Recipe_Card.png';
import LemonPeelImage from '../images/Lemon Peel_Recipe_Card.png';
import CucumberImage from '../images/Cucumber_Recipe_Card.png';
import PoppyGin from '../images/Poppy_Gin.jpg';
import '../css/RecipeDetailsPage.css';
import '../css/site.css';
import  API_URL  from '../config';

const RecipeDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [recipe, setRecipe] = useState({
    name: '',
    description: '',
    notes: '',
    batchnumber: 0,
    ingredients: [],
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editedRecipe, setEditedRecipe] = useState(null);
  const [ingredients, setIngredients] = useState([]);

  useEffect(() => {
    document.body.classList.add('bg-black');
    // Fetch the recipe by ID and populate the form fields
    // Replace this with your API call to retrieve the recipe details
    fetch(`${API_URL}/api/recipes/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setRecipe(data);
        setEditedRecipe(data);
      })
      .catch((error) => console.error(error));

    // Fetch the ingredients for rendering the options
    // Replace this with your API call to retrieve the ingredients
    fetch(`${API_URL}/api/ingredients`)
      .then((response) => response.json())
      .then((data) => setIngredients(data))
      .catch((error) => console.error(error));
  }, [id]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    fetch(`${API_URL}/api/recipes/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(editedRecipe),
    })
      .then((response) => {
        return response.text();
      })
      .then(() => {
        setIsEditing(false);
        setEditedRecipe(recipe);
        fetch(`${API_URL}/api/recipes/${id}`) // Fetch the updated recipe details
          .then((response) => response.json())
          .then((data) => {
            setRecipe(data);
            setEditedRecipe(data);
          })
          .catch((error) => console.error(error));
        navigate(`/recipes/${id}`);
      })
      .catch((error) => console.error(error));
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedRecipe(recipe);
  };



  const getIngredientAmount = (ingredientId) => {
    const ingredient = editedRecipe.ingredients.find((ing) => ing.id === ingredientId);
    return ingredient ? ingredient.amount : '';
  };

  const getIngredientType = (ingredientId) => {
    const ingredient = editedRecipe.ingredients.find((ing) => ing.id === ingredientId);
    return ingredient ? ingredient.type : '';
  };

  const handleIngredientAmountChange = (ingredientId, amount) => {
    setEditedRecipe((prevRecipe) => {
      const updatedIngredients = prevRecipe.ingredients.map((ing) => {
        if (ing.id === ingredientId) {
          return { ...ing, amount: amount };
        }
        return ing;
      });
      return { ...prevRecipe, ingredients: updatedIngredients };
    });
  };

  const handleIngredientTypeChange = (ingredientId, type) => {
    setEditedRecipe((prevRecipe) => {
      const updatedIngredients = prevRecipe.ingredients.map((ing) => {
        if (ing.id === ingredientId) {
          return { ...ing, type: type };
        }
        return ing;
      });
      return { ...prevRecipe, ingredients: updatedIngredients };
    });
  };

  const handleIngredientSelect = (e) => {
    const { value, checked } = e.target;
    const ingredientId = parseInt(value);

    if (checked) {
      const ingredientExists = editedRecipe.ingredients.some(
        (ingredient) => ingredient.id === ingredientId
      );

      if (!ingredientExists) {
        const selectedIngredient = ingredients.find(
          (ingredient) => ingredient.id === ingredientId
        );

        if (selectedIngredient) {
          setEditedRecipe((prevRecipe) => ({
            ...prevRecipe,
            ingredients: [
              ...prevRecipe.ingredients,
              { id: selectedIngredient.id, amount: 0, type: '' },
            ],
          }));
        }
      }
    } else {
      setEditedRecipe((prevRecipe) => ({
        ...prevRecipe,
        ingredients: prevRecipe.ingredients.filter(
          (ingredient) => ingredient.id !== ingredientId
        ),
      }));
    }
  };

  return (
    <div>
      <header>
        <nav className="navbar">
          <ul>
            <li>
              <Link to="/recipes">Recipes</Link>
            </li>
            <li>
              <Link to="/ingredients">Ingredients</Link>
            </li>
          </ul>
        </nav>
      </header>
      <div className="ingredients-card">
        {isEditing ? (
          <div className="ingredients">
            <div>
              <h2>Ingredients:</h2>
              {ingredients.map((ingredient) => (
                <div key={ingredient.id}>
                  <label>
                    <input
                      type="checkbox"
                      value={ingredient.id}
                      checked={editedRecipe.ingredients.some(
                        (ing) => ing.id === ingredient.id
                      )}
                      onChange={handleIngredientSelect}
                    />
                    {ingredient.name}
                  </label>
                  <input
                    type="number"
                    value={getIngredientAmount(ingredient.id)}
                    onChange={(e) =>
                      handleIngredientAmountChange(ingredient.id, e.target.value)
                    }
                  />
                  <input
                    type="text"
                    value={getIngredientType(ingredient.id)}
                    onChange={(e) =>
                      handleIngredientTypeChange(ingredient.id, e.target.value)
                    }
                  />
                </div>
              ))}
            </div>
            <button onClick={handleSave}>Save</button>
            <button onClick={handleCancel}>Cancel</button>
          </div>
        ) : (

          <div className="ingredients">
          <div className="ingredientHeaders">
            <h2>{recipe.name}</h2>
            <p>Batch Number: {recipe.batchnumber}</p>
            <p>Description: {recipe.description}</p>
            <p>Tasting Notes: {recipe.notes}</p>
            <h3>Ingredients:</h3>
            </div>
            <div className="card-stack">
                {recipe.ingredients.map((ingredient) => {
                  // Check if ingredient exists in the list
                  const ingredientImage = (
                    ingredient.name === 'Juniper' ? JuniperImage :
                    ingredient.name === 'Coriander' ? CorianderImage :
                    ingredient.name === 'Lemon Peel' ? LemonPeelImage :
                    ingredient.name === 'Cucumber' ? CucumberImage :
                    undefined
                  );

                  return (
                    <div className="card" key={ingredient.id}>
                      {ingredientImage ? (
                        <img
                          alt={ingredient.name}
                          src={ingredientImage}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = PoppyGin;
                          }}
                        />
                      ) : (
                        <img
                          alt={ingredient.name}
                          src={PoppyGin}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = PoppyGin;
                          }}
                          />
                      )}
                      <div className="card-details">
                        <div className="ingredient-name">{ingredient.name}</div>
                        <div className="ingredient-amount">{ingredient.amount} {ingredient.type}</div>

                      </div>
                    </div>
                  );
                })}
              </div>
            <button onClick={handleEdit}>Edit</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default RecipeDetailsPage;
