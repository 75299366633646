import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import API_URL  from '../config';

const RecipeFormPage = () => {
    const navigate = useNavigate();
  const { id } = useParams();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [batchnumber, setBatchnumber] = useState('');
  const [notes, setNotes] = useState('');
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [ingredients, setIngredients] = useState([]);

  useEffect(() => {
    // Fetch the list of ingredients from the server
    fetch(`${API_URL}/api/ingredients`)
      .then((response) => response.json())
      .then((data) => setIngredients(data))
      .catch((error) => console.error('Error fetching ingredients:', error));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Construct the recipe object with form data
    const recipe = {
      name,
      description,
      notes,
      batchnumber,
      ingredients: selectedIngredients,
    };

    // Send a POST or PUT request to the server based on the presence of `id`
    const request = id
      ? fetch(`${API_URL}/api/recipes/${id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(recipe),
        })
      : fetch(`${API_URL}/api/recipes`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(recipe),
        });

    request
      .then((response) => {
        if (response.ok) {
          alert(id ? 'Recipe updated successfully' : 'Recipe created successfully');
          navigate('/recipes');
        } else {
          throw new Error('Failed to create/update recipe');
        }
      })
      .catch((error) => {
        console.error('Error creating/updating recipe:', error);
        alert('Failed to create/update recipe. Please try again.');
      });
  };

  return (
    <div>
      <h1>{id ? 'Edit Recipe' : 'Create Recipe'}</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} required />
        </div>
        <div>
          <label htmlFor="batchnumber">Batch Number:</label>
          <input type="integer" id="batchnumber" value={batchnumber} onChange={(e) => setBatchnumber(e.target.value)} required></input>
        </div>
        <div>
          <label htmlFor="description">Description:</label>
          <textarea id="description" value={description} onChange={(e) => setDescription(e.target.value)} required></textarea>
        </div>
        <div>
          <label htmlFor="notes">Notes:</label>
          <textarea id="notes" value={notes} onChange={(e) => setNotes(e.target.value)}></textarea>
        </div>
        <div>
          <label htmlFor="ingredients">Ingredients:</label>
          <select
            id="ingredients"
            multiple
            value={selectedIngredients}
            onChange={(e) => setSelectedIngredients(Array.from(e.target.selectedOptions, (option) => option.value))}
          >
            {ingredients.map((ingredient) => (
              <option key={ingredient.id} value={ingredient.id}>
                {ingredient.name}
              </option>
            ))}
          </select>
        </div>
        <button type="submit">{id ? 'Update Recipe' : 'Create Recipe'}</button>
      </form>
    </div>
  );
};

export default RecipeFormPage;
