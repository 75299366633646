import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import RecipeListPage from './pages/RecipeListPage';
import RecipeDetailPage from './pages/RecipeDetailPage';
import RecipeFormPage from './pages/RecipeFormPage';
import IngredientsPage from './pages/IngredientsPage';
import Ingredients from './pages/Ingredients';
import HomePage from './pages/HomePage';
import './css/site.css';

const BackgroundImage = () => {
  const location = useLocation();

  const getBackgroundClass = () => {
    if (location.pathname.includes('/recipes')) {
      return 'recipes-background';
//    } else if (location.pathname.includes('/ingredients')) {
 //     return 'ingredients-background';
    } else {
      return 'body';
    }
  };

  useEffect(() => {
    const body = document.body;
    body.classList = '';
    body.classList.add(getBackgroundClass());
  }, [location.pathname]);

  return null;
};

const App = () => {
  return (
    <Router>
      <div className="container">
        <div className="heading">
          <h1>Slug Dog</h1>
          <img
            alt="Gin Logo"
            src={require('./images/poppy_gin.png')}
            style={{ height: '40vh' }}
          />
        </div>
        <div className="container">
          <BackgroundImage /> {/* Include the BackgroundImage component */}
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/recipes" element={<RecipeListPage />} />
            <Route path="/recipes/:id" element={<RecipeDetailPage />} />
            <Route path="/recipes/:id/edit" element={<RecipeFormPage />} />
            <Route path="/add-recipe" element={<RecipeFormPage />} />
            <Route path="/ingredients" element={<IngredientsPage />} />
            <Route path="/ingredients/add" element={<Ingredients />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
