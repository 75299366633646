import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import  API_URL  from '../config';

const IngredientsPage = () => {
  const [ingredients, setIngredients] = useState([]);

  useEffect(() => {
    // Fetch ingredients from the server
    fetch(`${API_URL}/api/ingredients`)
      .then((response) => response.json())
      .then((data) => setIngredients(data))
      .catch((error) => console.error('Error fetching ingredients:', error));
  }, []);

  const handleDeleteIngredient = async (id) => {
    try {
      // Send a DELETE request to the server to delete the ingredient
      const response = await fetch(`${API_URL}/api/ingredients/${id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        // Ingredient deleted successfully
        setIngredients(ingredients.filter((ingredient) => ingredient.id !== id));
        alert('Ingredient deleted!');
      } else {
        // Handle error response
        const errorData = await response.json();
        alert(`Failed to delete ingredient: ${errorData.error}`);
      }
    } catch (error) {
      // Handle network error
      console.error('Failed to delete ingredient:', error);
      alert('Failed to delete ingredient. Please try again.');
    }
  };

  return (
    <div>
        <header>
      <nav className='navbar'>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/recipes">Recipes</Link>
          </li>
        </ul>
      </nav>
    </header>
      <h1>Manage Ingredients</h1>
      <Link to="/ingredients/add">Add Ingredient</Link>
      <table>
        <thead>
          <tr>
            
            <th>Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {ingredients.map((ingredient) => (
            <tr key={ingredient.id}>
              <td>{ingredient.name}</td>
              <td>
                <button onClick={() => handleDeleteIngredient(ingredient.id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default IngredientsPage;
