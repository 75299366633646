import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import  API_URL  from '../config';

const AddIngredientPage = () => {
  const [name, setName] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Send a POST request to the API to add the ingredient
    try {
      const response = await fetch(`${API_URL}/api/ingredients`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name }),
      });

      if (response.ok) {
        // Ingredient added successfully
        alert('Ingredient added!');
        setName(''); // Clear the input field
      } else {
        // Handle error response
        const errorData = await response.json();
        alert(`Failed to add ingredient: ${errorData.error}`);
      }
    } catch (error) {
      // Handle network error
      console.error('Failed to add ingredient:', error);
      alert('Failed to add ingredient. Please try again.');
    }
  };

  return (
    <div>
       <header>
      <nav className='navbar'>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/recipes">Recipes</Link>
          </li>
        </ul>
      </nav>
    </header>
      <h1>Add Ingredient</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Ingredient Name:
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </label>
        <button type="submit">Add</button>
      </form>
      <Link to="/ingredients">Back</Link>
    </div>
  );
};

export default AddIngredientPage;
