import React from 'react';
import { Link } from 'react-router-dom';

const HomePage = () => (
    
    <header>
      <nav className='navbar'>
        <ul>
          <li>
            <Link to="/recipes">Recipes</Link>
          </li>
          <li>
            <Link to="/ingredients">Ingredients</Link>
          </li>
        </ul>
      </nav>
    </header>



  );

  export default HomePage;
